import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TopicSection from './TopicSection';

import content from '../../data/content.json';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '2rem',
  },
  title: {
    flexGrow: 1,
  },
}));

function CalendarList() {
  const classes = useStyles();

  const assignColors = (topics) => {
    let count = 0;
    const gradientColors = [
      'linear-gradient(135deg, #C5299B 0%, rgba(197,41,155,0.67) 100%)',
      'linear-gradient(135deg, #78BE20 0%, rgba(120,190,32,0.67) 100%)',
      'linear-gradient(135deg, #E87722 0%, rgba(232,119,34,0.67) 100%)',
      'linear-gradient(135deg, #00AB8E 0%, rgba(0,171,142,0.67) 100%)',
    ];
    topics.forEach((topic) => {
      topic.events.forEach((event) => {
        event.gradientColor = gradientColors[count % gradientColors.length];
        count += 1;
      });
    });
  };

  // Figure out background colors. Easier to do this way than CSS.
  assignColors(content.topics);

  return (
    <div className={classes.root}>
      {
        content.topics.map((topic) => (
          <TopicSection {...topic} />
        ))
      }
    </div>
  );
}

export default CalendarList;
