import { createMuiTheme } from '@material-ui/core/styles';

const theme = {
  themeName: 'Girls on the Run Theme',
  palette: {
    background: {
      default: '#F5F6F8',
    },
    primary: {
      main: '#6499F4',
      dark: '#4170C0',
    },
    secondary: {
      main: '#FEFEFE',
      dark: '#333333',
    },
    blue: {
      brand: '#6499F4',
      one: '#2B344E',
      two: '#343F60',
      three: '#4170C0',
      four: '#5891F3',
      five: '#E3EDFF',
      six: '#F5F9FF',
    },
    gray: {
      one: '#363637',
      two: '#52565F',
      three: '#868998 ',
      four: '#B1B6BF',
      five: '#EEEFF2',
      six: '#F6F7F9',
    },
    green: {
      one: '#236958',
      two: '#29836B',
      three: '#1FB89E',
      four: '#55FFE1',
      five: '#DFFFFC',
      six: '#F1FCF9',
    },
    black: {
      one: '#212429',
    },
    white: {
      one: '#FFFFFF',
    },
    orange: {
      one: '#961B0B',
      two: '#B32C1A',
      three: '#D7412D',
      four: '#FF6955',
      five: '#FFDCD8',
      six: '#FFF8F7',
    },
    pink: {
      one: '#A20027',
      two: '#B9294B',
      three: '#D14163',
      four: '#EE4C73',
      five: '#FFD9E2',
      six: '#FFF7F9',
    },
    purple: {
      one: '#1E1565',
      two: '#3A327D',
      three: '#5A50AF',
      four: '#695FC1',
      five: '#ECE9FF',
      six: '#F9F7FF',
    },
    yellow: {
      one: '#C29408',
      two: '#E2AD08',
      three: '#F7C322',
      four: '#FED860',
      five: '#FFF1C7',
      six: '#FFFBEE',
    },
    drawerNav: {
      backgroundColor: '#F5F6F8',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: [
      'Nunito Sans',
      'Roboto',
      'Helvetica',
      'Arial',
      '"Segoe UI"',
      'sans-serif',
    ].join(','),
    h1: {
      color: '#343F60',
      fontSize: '4.8rem',
      fontWeight: 300,
      lineHeight: '65px',
      fontFamily: ['Clarendon', 'Roboto', 'Helvetica', 'Arial', '"Segoe UI"', 'sans-serif'],
    },
    h2: {
      color: '#363637',
      fontSize: '1.8rem',
      fontWeight: 500,
      lineHeight: '24px',
      fontFamily: ['Clarendon', 'Roboto', 'Helvetica', 'Arial', '"Segoe UI"', 'sans-serif'],
    },
    h3: {
      color: '#363637',
      fontSize: '1.8rem',
      fontWeight: 600,
      lineHeight: '28px',
      fontFamily: ['Arial', 'Helvetica', '"Segoe UI"', 'sans-serif'],
    },
    h4: {
      color: '#343F60',
      fontSize: '2.6rem',
      fontWeight: 300,
      lineHeight: '35px',
    },
    h5: {
      color: '#363637',
      fontFamily: ['Nunito Sans Heavy', 'Roboto', 'Helvetica', 'Arial', '"Segoe UI"', 'sans-serif'],
      fontSize: '1.7rem',
      fontWeight: 600,
      lineHeight: '130%',
    },
    h6: {
      color: '#363637',
      fontFamily: ['Nunito Sans Heavy', 'Roboto', 'Helvetica', 'Arial', '"Segoe UI"', 'sans-serif'],
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '130%',
    },
    body1: {
      color: '#363637',
      fontSize: '1.7rem',
      fontWeight: 300,
      lineHeight: '130%',
    },
    body2: {
      color: '#363637',
      fontSize: '1.5rem',
      fontWeight: 300,
      lineHeight: '130%',
    },
    button: {
      fontSize: '1.2rem',
      fontFamily: ['Nunito Sans Heavy', 'Roboto', 'Helvetica', 'Arial', '"Segoe UI"', 'sans-serif'],
      fontWeight: 600,
      lineHeight: '130%',
      textTransform: 'uppercase',
    },
  },
};

export default createMuiTheme(theme);
