import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EventCard from './EventCard';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  topicTitle: {
    textAlign: 'left',
  },
}));

function TopicSection(props) {
  const classes = useStyles();

  const {
    title,
    events,
  } = props;

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.topicTitle}>
        {title}
      </Typography>
      {events.map((event) => (
        <EventCard {...event} />
      ))}
    </div>
  );
}

TopicSection.propTypes = {
  events: PropTypes.array,
  title: PropTypes.string,
};

TopicSection.defaultProps = {
  events: [],
  title: 'FPO',
};

export default TopicSection;
