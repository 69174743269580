import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import theme from './theme';
import TopBar from './components/TopBar';
import CalendarList from './components/calendar/CalendarList';
import './App.css';

const useStyles = makeStyles(() => ({
  root: {

  },
  '@font-face': {
    fontFamily: 'Clarendon',
    src: 'url(./fonts/Clarendon.otf) format(opentype)',
    fallbacks: [],
  },
}));

function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <TopBar />
        <CalendarList />
      </div>
    </ThemeProvider>
  );
}

export default App;
