import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    maxWidth: '360px',
    height: '142px',
    position: 'relative',
    borderRadius: '16px',
    background: '#D8D8D8',
    margin: '44px 0px',
    padding: '15px 20px',
    boxSizing: 'border-box',
  },
  eventTitle: {
    textAlign: 'left',
    position: 'absolute',
    bottom: '15px',
    color: theme.palette.white.one,
  },
}));

function EventCard(props) {
  const classes = useStyles();

  const {
    gradientColor,
    title,
  } = props;

  return (
    <div className={classes.root} style={{ backgroundImage: gradientColor }}>
      <Typography variant="h3" className={classes.eventTitle}>
        {title}
      </Typography>
    </div>
  );
}

EventCard.propTypes = {
  gradientColor: PropTypes.string.isRequired,
  title: PropTypes.string,
};

EventCard.defaultProps = {
  title: 'FPO',
};

export default EventCard;
